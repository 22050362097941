import { Component } from 'react';
import { parse } from 'query-string';
import { fetchEntry } from '../../contentful/apiHelper';

export default class PreviewPage extends Component {
  constructor(props) {
    super(props);
    this.previewEntryId = parse(this.props.location.search).contentPreview;

    this.state = {};
  }
  componentWillMount = () => {
    if (this.previewEntryId) {
      // fetch content preview API
      fetchEntry(true, this.previewEntryId).then(res => {
        this.setState({ previewData: res });
      });
    }
  };
}
