export const generateItem = item => ({
  entryID: item.sys.id,
  ...item.fields,
});

export const extractFields = item => ({
  ...item.fields,
});

export const entryOverviewSerializer = res => {
  // mapping of serializers to be used for different content types
  const serializers = {
    projectDetailPage: generateItem,
    talentDetailPage: generateItem,
    teamMember: generateItem,
    homePage: extractFields,
    progressStep: extractFields,
    galleryPage: extractFields,
    servicesPage: extractFields,
    talentsPage: extractFields,
    aboutPage: extractFields,
    award: extractFields,
    imprintPage: extractFields,
    dataProtection: extractFields,
    errorPage: extractFields,
    client: extractFields,
    contactData: extractFields,
  };

  if (res && res.items) {
    return res.items.reduce((serializedObject, item) => {
      if (item && item.sys) {
        const { id } = item.sys.contentType.sys;
        if (serializedObject[id]) {
          // key already exists?
          serializedObject[id] = [
            ...serializedObject[id],
            serializers[id](item),
          ];
        } else {
          if (serializers[id]) {
            // create key
            serializedObject[id] = [serializers[id](item)];
          }
        }
        return serializedObject;
      } else return serializedObject;
    }, {});
  }
};
