import React, { Component } from 'react';
import Img from 'gatsby-image';
import { srcSetGenerator } from '../../contentful/apiHelper';

const ResponsiveBgImage = props => {
  const { children, className, image, imageStyle } = props;

  return (
    <div
      className={className}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <Img
        alt="background image"
        fluid={image.fluid}
        style={{
          width: '100%',
          height: '100%',
          ...(props.fixedBgImage && { position: 'fixed' }),
          ...imageStyle,
        }}
      />
      {children}
    </div>
  );
};

export default ResponsiveBgImage;
