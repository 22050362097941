import React from 'react';
import './ListWithHeadline.scss';

const ListWithHeadline = ({ headline, list, listIsLink = false }) => (
  <div className={'listWithHeadline'}>
    <h5 className={'typography__header5'}>{headline}</h5>
    <ul className="listWithHeadline__list">
      {Array.isArray(list) ? (
        list.map((li, idx) => (
          <li
            className={'typography__paragraph typography--light'}
            key={`${headline}-${idx}`}
          >
            {li}
          </li>
        ))
      ) : (
        <li
          className={`typography__paragraph ${
            listIsLink ? 'typography__link' : ''
          }`}
        >
          {list}
        </li>
      )}
    </ul>
  </div>
);

export default ListWithHeadline;
