import React from 'react';
import { Link } from 'gatsby';
import './Footer.scss';
import {
  switchLocaleRoute,
  getLocaleFromPathname,
  getLinkTo,
} from '../../i18n/helpers';
import { locales } from '../../i18n/config';
import { RichTextRenderer } from '../index';

export const FooterNavigationLinks = ({
  onArrowClick,
  pathname,
  query,
  navigationParagraph,
}) => (
  <div className="footer__links">
    <div>
      {locales[getLocaleFromPathname(pathname)].linksFooter.map(link => (
        <Link
          key={`footer-${link}`}
          className="typography__link typography--light"
          to={getLinkTo(getLocaleFromPathname(pathname), link)}
        >
          {link}
        </Link>
      ))}
    </div>
    <div>
      <RichTextRenderer content={navigationParagraph} light noWrap />
    </div>
    <div className="footer__languageSwitch">
      {Object.keys(locales).map((locale, idx) => (
        <Link
          key={`locale-${idx}`}
          className={`typography__navLink ${
            locale === getLocaleFromPathname(pathname)
              ? 'typography__navLink--active'
              : ''
          }`}
          to={switchLocaleRoute(locale, pathname, query)}
        >
          {locales[getLocaleFromPathname(pathname)].names[idx]}
        </Link>
      ))}

      <div className="footer__arrow">
        <img
          src="assets/icons/scroll_up.svg"
          alt="arrow_up-icon"
          style={{ cursor: 'pointer' }}
          onClick={onArrowClick}
        />
      </div>
    </div>
  </div>
);

export default FooterNavigationLinks;
