import React from 'react';
import classnames from 'classnames';
import './FullViewportStage.scss';

const FullViewportStage = ({
  centered,
  black,
  spacedTopBottom,
  spaced,
  regularOverflow,
  notFullHeight,
  children,
  style,
}) => (
  <section
    style={style}
    className={`fullViewportStage ${classnames({
      'fullViewportStage--centered': centered,
      'fullViewportStage--spaced': spaced,
      'fullViewportStage--spacedTopBottom': spacedTopBottom,
      'fullViewportStage--blackBg': black,
      'fullViewportStage--regularOverflow': regularOverflow,
      'fullViewportStage--notFullHeight': notFullHeight,
    })}`}
  >
    {children}
  </section>
);

export default FullViewportStage;
