import React, { Component } from 'react';
import './ProgressSlider.scss';
import classnames from 'classnames';
import ResponsiveBgImage from '../ResponsiveBgImage/ResponsiveBgImage';

class ProgressSlider extends Component {
  constructor(props) {
    super(props);
    if (!this.props.hasStaticLayout) {
      this.verticalSlider = React.createRef();
    }

    this.activeIdx = 0;
  }

  render() {
    const { progressSteps, scrollTop } = this.props;
    const height = this.verticalSlider.current
      ? this.verticalSlider.current.clientHeight
      : undefined;
    const viewportHeight = typeof window !== 'undefined' && window.innerHeight;
    const triggerOffset = viewportHeight / 2;

    let sliderNode, sliderScrollTop, children;

    if (this.verticalSlider.current) {
      sliderNode = this.verticalSlider.current;
      sliderScrollTop = this.props.scrollTop;
      children = Array.prototype.slice.call(sliderNode.children);
      // check child's position in relation to parent scroll
      children.forEach((child, idx) => {
        if (sliderScrollTop + triggerOffset > child.offsetTop) {
          // if the vertical position of the parent container + a certain offset is
          // larger than the child's top offset, we know that the child has passed
          // the center of the parent and it's index is the currently active one
          this.activeIdx = idx - 1;
        }
      });
    }

    return (
      <div className="progressSlider">
        <div
          className={classnames([
            'progressSlider__left',
            {
              'progressSlider__left--fixed':
                scrollTop < height - viewportHeight,
            },
            {
              'progressSlider__left--docked':
                scrollTop >= height - viewportHeight || scrollTop === 0,
            },
          ])}
        >
          {progressSteps.map((step, idx) => (
            <div
              className="progressSlider__imageContainer"
              key={`image-${step.step}`}
            >
              <div
                className={`progressSlider__image ${classnames({
                  'progressSlider__image--active': idx === this.activeIdx,
                  'progressSlider__image--inactive': idx < this.activeIdx,
                })}`}
              >
                <ResponsiveBgImage image={step.highResImage} />
              </div>
              <div className="progressSlider__mobileTexts">
                <h6 className="typography typography__header6">
                  {`0${step.step}-0${progressSteps.length}`}
                </h6>
                <h3 className="typography typography__header3 typography--uppercase progressSlide__headline">
                  {step.stepName}
                </h3>
                <h6 className="typography typography__header6">
                  {step.description.description}
                </h6>
              </div>
            </div>
          ))}
        </div>
        <div className="progressSlider__right" ref={this.verticalSlider}>
          <div
            style={{
              backgroundColor: 'black',
              position: 'absolute',
              height: '80px',
              width: '100%',
              top: '0',
            }}
          />
          {progressSteps.map(step => (
            <Slide
              step={step}
              length={progressSteps.length}
              key={`step-${step.step}`}
            />
          ))}
        </div>
      </div>
    );
  }
}

const Slide = ({ step, length }) => (
  <div className="progressSlide__container">
    <h6 className="typography typography__header6">
      {`0${step.step}-0${length}`}
    </h6>
    <h3 className="typography typography__header3 typography--uppercase progressSlide__headline">
      {step.stepName}
    </h3>
    <h6 className="typography typography__header6">
      {step.description.description}
    </h6>
  </div>
);

export default ProgressSlider;
