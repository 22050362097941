import React from 'react';
import classnames from 'classnames';
import './BackGroundImageStage.scss';
import BackgroundImage from 'gatsby-background-image';

const BackGroundImageStage = ({
  imageLow,
  img,
  children,
  isFixed,
  fixedBgImage,
}) => {
  return (
    <section
      className={`BackGroundImageStage ${classnames({
        'BackGroundImageStage--isFixed': isFixed,
        'BackGroundImageStage--bgImageIsFixed': fixedBgImage,
      })}`}
    >
      <BackgroundImage fluid={img.fluid}>{children}</BackgroundImage>
    </section>
  );
};

export default BackGroundImageStage;
