import React, { Component } from 'react';
import { Footer } from '../../components';
import './SinglePageLayout.scss';

class SinglePageLayout extends Component {
  constructor(props) {
    super(props);
    this.pageRef = React.createRef(); // Create a ref object
  }

  handleFilterChange = filter => {
    this.setState({
      filter,
      visibleItems:
        filter === 'all'
          ? this.props.data
          : this.props.data.filter(item => item.category === filter),
    });
  };

  render() {
    const goToTop = () => {
      this.pageRef.current.scrollTo(0, 0);
    };

    return (
      <div className={'singlePageContainer'} ref={this.pageRef}>
        <div className={'blackBG'} />
        {this.props.children}
        <Footer onArrowClick={() => goToTop()} />
      </div>
    );
  }
}

export default SinglePageLayout;
