import React from 'react';
import Img from 'gatsby-image';
import './Clients.scss';
import { getLinkTo, getLocaleFromPathname } from '../../i18n/helpers';
import { Link } from 'gatsby';

const Clients = ({ headline, clients, seeAll, pathname, isReduced }) => (
  <div
    className={`clients__container ${
      isReduced ? 'clients__container--reduced' : ''
    }`}
  >
    <div
      className={`clients__headline ${
        isReduced ? 'clients__headline--reduced' : ''
      }`}
    >
      <h2
        id="clients"
        className={'typography typography__header2'}
        style={{ paddingBottom: '40px' }}
      >
        {headline}
      </h2>
      {seeAll && (
        <Link
          to={`${getLinkTo(
            getLocaleFromPathname(pathname),
            getLocaleFromPathname(pathname) === 'en' ? 'services' : 'angebot'
          )}#clients-3`}
        >
          <p
            className={
              'typography__link typography__link--readMore clients__seeAll'
            }
          >
            {seeAll}
          </p>
        </Link>
      )}
    </div>
    {clients.map(client => {
      return (
        <Img
          alt={client.clientName}
          className={`clients__image ${
            isReduced ? 'clients__image--reduced' : ''
          }`}
          key={client.clientName}
          fluid={client.clientLogo.fluid}
        />
      );
    })}
  </div>
);

export default Clients;
