import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Location } from '@reach/router';
import AnimatedBackgroundBox from '../AnimatedBackgroundBox/AnimatedBackgroundBox';
import './FooterNav.scss';

class DetailPageFooterNav extends Component {
  constructor(props) {
    super(props);

    this.categoryQuery =
      props.currentItem &&
      props.currentItem.category === props.categoryQuery &&
      props.allItems.some(el => el.category === props.categoryQuery)
        ? props.categoryQuery
        : null;
    this.allItems = this.categoryQuery
      ? props.allItems.filter(el => el.category === this.categoryQuery)
      : props.allItems;
    this.currentItemIndex = this.allItems.findIndex(
      item => item.sluggedName === props.currentItem.sluggedName
    );
    this.previousItemIndex =
      this.currentItemIndex === 0
        ? this.allItems.length - 1
        : this.currentItemIndex - 1;
    this.nextItemIndex =
      this.currentItemIndex + 1 > this.allItems.length - 1
        ? 0
        : this.currentItemIndex + 1;
  }

  getImage(isPrevious) {
    let index = isPrevious ? this.previousItemIndex : this.nextItemIndex;
    if (this.allItems[index].previewImages) {
      return this.allItems[index].previewImages[0];
    } else {
      return this.allItems[index].image;
    }
  }

  checkForData() {
    return this.props.currentItem && this.allItems;
  }

  getLink(location, isPrevious) {
    const index = isPrevious ? this.previousItemIndex : this.nextItemIndex;

    const basePath = location.pathname.split(
      this.props.currentItem.sluggedName
    )[0];

    const thisLinkSluggedName = this.allItems[index].sluggedName;

    const categoryQuery = this.categoryQuery
      ? '?category=' + this.categoryQuery
      : '';

    return `${basePath}${thisLinkSluggedName}/${categoryQuery}`;
  }

  render() {
    return (
      <Location>
        {({ location }) => (
          <div className="footerNav">
            {this.checkForData() &&
              this.props.labels.map((el, i) => (
                <Link
                  key={el}
                  className="footerNav__tile footerNav__tile--detail"
                  to={this.getLink(location, i === 0)}
                  state={{
                    filteredItems: this.props.allItems,
                  }}
                >
                  <AnimatedBackgroundBox
                    bg={this.getImage(i === 0)}
                    zoomBG
                    hasNoHover
                  >
                    <h3 className="typography__header3 typography--uppercase footerNav__link">
                      {el}
                    </h3>
                  </AnimatedBackgroundBox>
                </Link>
              ))}
          </div>
        )}
      </Location>
    );
  }
}

export default DetailPageFooterNav;
