import React from 'react';
import './ItemFilter.scss';
import { DropDown } from '../index';

const ItemFilter = ({ text, filters, setFilter, activeFilter }) => (
  <div className={'itemFilter'}>
    <div className={'filterBar'}>
      {filters.map((category, idx) => (
        <div
          key={category}
          className={'filterBar__filterButton'}
          onClick={() => setFilter(category)}
        >
          <span
            className={`typography__caption filterBar__buttonText ${
              activeFilter === category ? 'filterBar__buttonText--active' : ''
            }`}
          >
            {text && text.filterNames ? text.filterNames[idx] : ''}
          </span>
        </div>
      ))}
    </div>
    <DropDown
      label={'Category'}
      defaultOption={activeFilter}
      onChange={e => setFilter(e.value)}
      options={filters.map((category, idx) => ({
        value: category,
        label: text && text.filterNames ? text.filterNames[idx] : '',
      }))}
    />
  </div>
);

export default ItemFilter;
