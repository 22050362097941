import React, { Fragment } from 'react';
import { Location } from '@reach/router';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header';
import { getLocaleFromPathname, shouldDisplayHeader } from '../../i18n/helpers';

interface LayoutProps {}

const Layout: React.FC<LayoutProps> = props => {
  const { children } = props;
  return (
    <Location>
      {({ location }) => (
        <Fragment>
          <Helmet>
            <html lang={getLocaleFromPathname(location.pathname)} />
            <title>Dropout Films</title>
            <link rel="canonical" href="http://www.dropout-films.de/" />
          </Helmet>
          <div className={'App'}>
            {shouldDisplayHeader(location.pathname) && <Header />}
            <div className={'App__pageContent'}>{children}</div>
          </div>
        </Fragment>
      )}
    </Location>
  );
};

export default Layout;
