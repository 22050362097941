import React from 'react';
import './AwardsList.scss';

const AwardsList = ({ awards, showSelected }) => {
  const filteredAwards = showSelected
    ? awards.filter(a => a.isVisibleOnLanding === 'yes')
    : awards;
  return (
    <div className="awards">
      {filteredAwards.map(award => (
        <div key={award.title} className="awards__container">
          <p className="typography__testimonial award__text">{award.title}</p>
        </div>
      ))}
    </div>
  );
};

export default AwardsList;
