import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router';
import { FooterNavigationLinks } from './FooterNavigationLinks';
import { FooterCaption } from './FooterCaption';
import { getLocaleFromPathname } from '../../i18n/helpers';
import { ContactInfoBlock } from '../../components';
import './Footer.scss';
import '../../contentful/fragments';

const Footer = ({ onArrowClick }) => {
  const contactDataQueryResult = useStaticQuery(graphql`
    query {
      en: allContentfulContactData(filter: { node_locale: { eq: "en-US" } }) {
        nodes {
          ...contactDataFragment
        }
      }

      de: allContentfulContactData(filter: { node_locale: { eq: "de" } }) {
        nodes {
          ...contactDataFragment
        }
      }
    }
  `);

  const contactData = {
    en: contactDataQueryResult.en.nodes[0],
    de: contactDataQueryResult.de.nodes[0],
  };

  return (
    <Location>
      {({ location }) => (
        <footer className="footer">
          <div className="footer__logoContainer">
            <Link to={`/${getLocaleFromPathname(location.pathname)}/`}>
              <img
                style={{ width: '29px', height: '40px' }}
                alt={'Dropout Films logo'}
                src={'assets/icons/logo.svg'}
              />
            </Link>
          </div>
          <div className="footer__innerContainer">
            <ContactInfoBlock
              contactData={
                contactData[getLocaleFromPathname(location.pathname)]
              }
              reduced
            />
            {contactData[getLocaleFromPathname(location.pathname)] &&
              contactData[getLocaleFromPathname(location.pathname)]
                .textBlock3 && (
                <FooterNavigationLinks
                  onArrowClick={onArrowClick}
                  pathname={location.pathname}
                  query={location.search}
                  navigationParagraph={
                    contactData[getLocaleFromPathname(location.pathname)]
                      .textBlock3.json
                  }
                />
              )}
          </div>

          <FooterCaption pathname={location.pathname} />
        </footer>
      )}
    </Location>
  );
};

export default Footer;
