import React from 'react';
import './Transitions.scss';
import classnames from 'classnames';

function SlideTransition({
  active,
  previousActiveScreenIndex,
  index,
  children,
  activeScreenIndex,
  deltaY,
}) {
  return (
    <div
      className={classnames(
        'transition__slide',
        { 'transition__slide--active': active },
        {
          'transition__slide--in': previousActiveScreenIndex < index && active,
        },
        {
          'transition__slide--out':
            previousActiveScreenIndex === index && activeScreenIndex < index,
        },
        {
          'transition__slide--static':
            (active && previousActiveScreenIndex > index) ||
            (!active && activeScreenIndex > index),
        },
        {
          'transition__slide--outPartial': active && deltaY > 0,
        },
        {
          'transition__slide--inPartial':
            activeScreenIndex === index - 1 && deltaY < 0,
        }
      )}
    >
      {children}
    </div>
  );
}

export default SlideTransition;
