import React from 'react';
import { Link } from 'gatsby';
import { getLocaleFromPathname, stringToSlug } from '../../i18n/helpers';

const captionContent = {
  de: {
    captionText: 'Alle Rechte vorbehalten',
    imprintLink: 'Impressum',
    dataProtectionLink: 'Datenschutz',
  },
  en: {
    captionText: 'All rights reserved',
    imprintLink: 'Imprint',
    dataProtectionLink: 'Data Protection',
  },
};

export const FooterCaption = ({ pathname }) => {
  let locale = getLocaleFromPathname(pathname);

  return (
    <div className="footer__caption">
      <p className="typography__caption">
        {captionContent[locale].captionText}{' '}
        <Link
          className="typography__caption"
          to={`/${locale}/${stringToSlug(captionContent[locale].imprintLink)}/`}
        >
          {captionContent[locale].imprintLink}
        </Link>
        {' / '}
        <Link
          className="typography__caption"
          to={`/${locale}/${stringToSlug(
            captionContent[locale].dataProtectionLink
          )}/`}
        >
          {captionContent[locale].dataProtectionLink}
        </Link>
      </p>
    </div>
  );
};
