import React, { Fragment } from 'react';
import { RichTextRenderer } from '../index';
import './ContactInfoBlock.scss';

const ContactInfoBlock = ({ contactData, reduced }) => (
  <Fragment>
    {!reduced ? (
      <div className="contact">
        <h5 className="typography__header5 contact__headline typography--uppercase">
          {contactData && contactData.headline}
        </h5>
        <div className="contact__row">
          <div className="contact__column">
            <RichTextRenderer
              content={
                contactData &&
                contactData.textBlock1 &&
                contactData.textBlock1.json
              }
              light
              noWrap
            />
          </div>
          <div className="contact__column">
            <RichTextRenderer
              content={
                contactData &&
                contactData.textBlock2 &&
                contactData.textBlock2.json
              }
              light
              noWrap
            />
          </div>
          <div className="contact__column">
            <RichTextRenderer
              content={
                contactData &&
                contactData.textBlock3 &&
                contactData.textBlock3.json
              }
              light
              noWrap
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="footerContact">
        <h5 className="typography__header5 footerContact__headline typography--extraBold-italic">
          {contactData && contactData.headline}
        </h5>
        <div className="footerContact__row">
          <div className="footerContact__column">
            <RichTextRenderer
              content={
                contactData &&
                contactData.textBlock1 &&
                contactData.textBlock1.json
              }
              light
            />
          </div>
          <div className="footerContact__column">
            <RichTextRenderer
              content={
                contactData &&
                contactData.textBlock2 &&
                contactData.textBlock2.json
              }
              light
            />
          </div>
        </div>
      </div>
    )}
  </Fragment>
);

export default ContactInfoBlock;
