import React from 'react';
import SlideTransition from './Transitions/SlideTransition';
import classnames from 'classnames';

class CarouselScreen extends React.Component {
  constructor(props, state) {
    super(props, state);
    this.screenContainerRef = React.createRef();
    this.contentsContainerRef = React.createRef();

    this.wasActive = props.activeScreenIndex === props.index; // memoization for whether screen was active last render

    this.state = {
      next: true,
      previous: true,
      scrollTop: 0,
    };
  }

  static displayName = 'CarouselScreen';

  handleScroll = e => {
    // safari's desktop bounce is effect resistant to any possible CSS property (overflow auto, webkit-overflow-scroll, etc)
    if (
      e &&
      e.deltaY &&
      this.props.scrollable &&
      this.screenContainerRef.current.scrollTo
    ) {
      if (this.scrollPosition + e.deltaY < 0) {
        e.preventDefault();
        this.screenContainerRef.current.scrollTo(0, 0);
      } else if (this.remainingScrollArea - e.deltaY <= 0) {
        e.preventDefault();
        this.screenContainerRef.current.scrollTo(0, this.scrollHeight);
      }
    }
    this.updateScrollNavigation(e);
  };

  scrollToTop = () => {
    this.screenContainerRef.current.scrollTop = 0;
  };

  scrollToBottom = () => {
    this.screenContainerRef.current.scrollTop = 0;
  };

  componentWillUpdate = (nextProps, nextState) => {
    if (!this.props.active && nextProps.active) {
      this.props.activeScreenIndex < nextProps.activeScreenIndex
        ? this.scrollToTop()
        : this.scrollToBottom();
    }
  };

  updateScrollNavigation = e => {
    this.scrollPosition = this.screenContainerRef.current.scrollTop;
    this.scrollHeight = this.contentsContainerRef.current.scrollHeight;
    this.viewportHeight = this.screenContainerRef.current.clientHeight; // actually, not the window -- at least not on mobile

    this.remainingScrollArea = Math.floor(
      this.scrollHeight - (this.viewportHeight + this.scrollPosition)
    );

    this.setState(
      {
        previous:
          (this.scrollPosition <= 0 || !this.props.scrollable) &&
          this.props.index !== 0,
        next: this.remainingScrollArea <= 0 || !this.props.scrollable,
        scrollTop: this.scrollPosition,
        lastEvent: e,
      },
      () => {
        this.props.setScrollingNavigation({
          previous: this.state.previous,
          next: this.state.next,
        });
      }
    );
  };

  componentDidMount() {
    this.screenContainerRef.current.addEventListener('scroll', e =>
      this.handleScroll(e)
    );

    this.screenContainerRef.current.addEventListener('wheel', e =>
      this.handleScroll(e)
    );

    this.screenContainerRef.current.addEventListener('touchmove', () =>
      this.handleScroll()
    );

    if (this.props.active) {
      this.updateScrollNavigation();
    }
  }

  componentWillUnmount() {
    this.screenContainerRef.current.removeEventListener(
      'scroll',
      () => this.handleScroll
    );
  }

  render() {
    const Transition = this.props.transition
      ? this.props.transition
      : SlideTransition;

    // has rendered itself once already and is becoming active. this triggers a warning
    if (
      this.contentsContainerRef.current &&
      this.screenContainerRef.current &&
      !this.wasActive &&
      this.props.index === this.props.activeScreenIndex
    ) {
      this.updateScrollNavigation();
    }

    this.wasActive = this.props.active;

    let extendedChildren = React.Children.toArray(this.props.children).map(
      child => {
        if (child) {
          return React.cloneElement(child, {
            scrollTop: this.state.scrollTop,
            lastScrollingEvent: this.state.lastEvent,
            scrollToTop: this.scrollToTop,
            goToScreen: this.props.goToScreen,
          });
        }
        return false;
      }
    );

    return (
      <div
        className={`carousel__screen ${
          this.props.active ? 'carousel__screen--active' : ''
        }`}
      >
        <Transition {...this.props}>
          <div
            ref={this.screenContainerRef}
            className={classnames('carousel__screenContainer', {
              'carousel__screenContainer--scrollable':
                this.props.scrollable && this.props.deltaY === 0,
            })}
          >
            <div
              ref={this.contentsContainerRef}
              className={classnames([
                'carousel__screenContentContainer',
                {
                  scrollable: this.props.scrollable && this.props.deltaY === 0,
                },
              ])}
            >
              {extendedChildren}
            </div>
          </div>
        </Transition>
      </div>
    );
  }
}

export default CarouselScreen;
