export const locales = {
  en: {
    linksFooter: ['Home', 'Gallery', 'Services', 'Talents', 'About'],
    linksHeader: ['Gallery', 'Services', 'Home', 'Talents', 'About'],
    names: ['English', 'German'],
  },
  de: {
    linksFooter: ['Home', 'Galerie', 'Angebot', 'Talente', 'Über uns'],
    linksHeader: ['Galerie', 'Angebot', 'Home', 'Talente', 'Über uns'],
    names: ['Englisch', 'Deutsch'],
  },
};

export const pathnames = {
  home: 'home',
  gallery: 'galerie',
  services: 'angebot',
  talents: 'talente',
  about: 'ueber-uns',
  imprint: 'impressum',
  // eslint-disable-next-line
  ['data-protection']: 'datenschutz',
};
