import React from 'react';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import './SplitBG.scss';

const SplitBG = ({ images, children }) => (
  <section className={'splitBackGround'}>
    <div className="splitBackGround__leftContainer">
      <BackgroundImage
        className="splitBackGround__leftImage"
        fluid={images[0].fluid}
      />
    </div>
    <div className="splitBackGround__rightContainer">
      <BackgroundImage
        className="splitBackGround__rightImage"
        fluid={images[1].fluid}
      />
    </div>
    <div className="splitBackGround__content">{children}</div>
    <Img
      alt="our team members"
      className="splitBackGround__mobileImage"
      fluid={images[0].fluid}
    />
    <Img
      alt="our team members"
      className="splitBackGround__mobileImage"
      fluid={images[1].fluid}
    />
  </section>
);

export default SplitBG;
