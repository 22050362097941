import React, { Component } from 'react';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { Transition } from 'react-transition-group';
import './VideoStage.scss';
import ResponsiveBgImage from '../ResponsiveBgImage/ResponsiveBgImage';
import FullViewportStage from '../FullViewportStage/FullViewportStage';

const duration = 150;

const defaultStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  opacity: 0,
  width: 0,
  height: 0,
  transition: `all ${duration}ms ease`,
};

const transitionStyles = {
  entering: {
    top: 0,
    left: 0,
    opacity: 1,
    width: '100%',
    height: '100%',
  },
  entered: {
    top: 0,
    left: 0,
    opacity: 1,
    width: '100%',
    height: '100%',
  },
  exiting: {
    opacity: 0,
  },
};

class VideoStage extends Component {
  state = {
    showVideo: false,
    playerError: false,
  };
  render() {
    const {
      imageBackgroundSrc,
      videoBackground,
      videoId,
      onLoadVideo,
      openVideo,
      onClose,
      fixedBackground,
      children,
    } = this.props;

    return (
      <section
        className={`VideoStageSection ${
          fixedBackground ? 'VideoStageSection--isFixed' : ''
        } ${openVideo ? 'VideoStageSection--overlay' : ''} scrollable`}
      >
        {videoBackground ? (
          <VideoPlayer
            videoId={videoId}
            onLoadVideo={() => this.setState({ showVideo: true })}
            onPlayerError={() =>
              this.setState({ playerError: true }, () =>
                console.error('Player has thrown an exception.')
              )
            }
            muted={true}
          >
            <FullViewportStage centered>
              {(!this.state.showVideo || this.state.playerError) && (
                <ResponsiveBgImage
                  image={imageBackgroundSrc}
                  imageStyle={{
                    position: 'inherit',
                  }}
                />
              )}
              {children}
            </FullViewportStage>
          </VideoPlayer>
        ) : (
          <Transition in={openVideo} timeout={0}>
            {state => (
              <div
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state],
                }}
              >
                {openVideo && (
                  <VideoPlayer
                    videoId={videoId}
                    onLoadVideo={onLoadVideo}
                    openVideo={openVideo}
                    onClose={onClose}
                    videoControls
                    muted={false}
                  />
                )}
              </div>
            )}
          </Transition>
        )}
      </section>
    );
  }
}

export default VideoStage;
