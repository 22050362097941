import React from 'react';
import './GalleryItem.scss';
import { AnimatedBackgroundBox } from '../index';
import { Link } from 'gatsby';

const GalleryItem = ({ item, linkLabel, categoryLabel, queryString }) => {
  return (
    <Link className={'galleryItem'} to={queryString}>
      <AnimatedBackgroundBox bg={item.previewImages}>
        <h6 className={'typography__header6 galleryItem__brandName'}>
          {item.client}
        </h6>
        <h4 className={'typography__header4 galleryItem__projectName'}>
          {item.projectName}
        </h4>
        <h6 className={'typography__header6 galleryItem__category'}>
          {categoryLabel}
        </h6>
        <p
          className={
            'typography__paragraph typography--bold galleryItem__projectLink'
          }
        >
          {linkLabel}
        </p>
      </AnimatedBackgroundBox>
    </Link>
  );
};

export default GalleryItem;
