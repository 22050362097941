import React from 'react';
import './DetailGrid.scss';

const Container = ({ label, top, bottom, noPaddingTop }) => (
  <div
    className={`container  ${noPaddingTop ? 'container--noPaddingTop' : ''}`}
  >
    <Row className={'container__row--breakOnTablet1'}>
      <div className={'container__leftCol'}>
        <Cell className={'container--hideOnDesktop'}>
          <h6 className={'typography__header6'}>{label}</h6>
        </Cell>
      </div>
      <div className={`container__rightCol`}>{top}</div>
    </Row>

    <Row className={'container__row--breakOnTablet1'}>
      <div className={'container__leftCol'}>
        <Cell className={'container--hideOnMobile'}>
          <h6 className={'typography__header6'}>{label}</h6>
        </Cell>
      </div>
      <div className={'container__rightCol'}>{bottom}</div>
    </Row>
  </div>
);

const Row = ({ className, children }) => (
  <div className={`container__row ${className ? className : ''}`}>
    {children}
  </div>
);

const Cell = ({ className, children }) => (
  <div className={`container__cell ${className ? className : ''}`}>
    {children}
  </div>
);

const DetailGrid = {
  Container,
  Cell,
  Row,
};
export default DetailGrid;
