import React from 'react';
import ReactDropdown from 'react-dropdown';
import './DropDown.scss';

const DropDown = ({ options, onChange, defaultOption, block, label }) => {
  return (
    <div className={'selectContainer'}>
      {label && (
        <span
          className={
            'typography__paragraph typography--bold typography__uppercase'
          }
        >
          {label}
        </span>
      )}
      <ReactDropdown
        className={`dropDown${block ? '--block' : ''}`}
        controlClassName="dropDown__control"
        placeholderClassName="dropDown__placeholder"
        menuClassName="dropDown__menu"
        arrowClassName="dropDown__arrow"
        options={options}
        onChange={onChange}
        value={defaultOption}
        placeholder="Select an option"
        arrowClosed={
          <span className="dropDown__arrow dropDown__arrow--closed" />
        }
        arrowOpen={<span className="dropDown__arrow dropDown__arrow--open" />}
      />
    </div>
  );
};

export default DropDown;
