import { createClient } from 'contentful';
import { contentfulAPI } from './config';
import { entryOverviewSerializer, generateItem } from './serializer';

const { spaceID, accessToken, previewAccessToken } = contentfulAPI;

let client, previewClient;

function connectContentful() {
  client = createClient({
    space: spaceID,
    accessToken: accessToken,
  });
  previewClient = createClient({
    space: spaceID,
    accessToken: previewAccessToken,
    host: 'preview.contentful.com',
  });
}

const basicOptions = {
  resolveLinks: true,
  include: 10,
  limit: 1000,
};

export const fetchInitialContentful = async () => {
  const en = await fetchEntriesOverview('en-US');
  const de = await fetchEntriesOverview('de');
  if (en && de) {
    return {
      en: entryOverviewSerializer(en),
      de: entryOverviewSerializer(de),
    };
  } else return new Error('Could not fetch data');
};

export const fetchEntriesOverview = async lang => {
  const options = {
    ...basicOptions,
    locale: lang,
  };
  client || connectContentful();
  return client.getEntries(options);
};

export const fetchEntriesByType = async (lang, type, preview) => {
  const options = {
    ...basicOptions,
    locale: lang,
    type,
  };
  client || connectContentful();
  return (preview ? previewClient : client).getEntries(options);
};

export const fetchEntry = async (preview, entryID) => {
  return (preview ? previewClient : client)
    .getEntry(entryID)
    .then(res => generateItem(res));
};

export const bpMax = {
  mobile: 768,
  tablet1: 1024,
  tablet2: 1280,
  desktop: 2600,
};

export const generateResponsiveImages = imgURL => ({
  mobile: `${imgURL}?w=${bpMax.mobile}&fit=thumb`,
  tablet1: `${imgURL}?w=${bpMax.tablet1}&fit=thumb`,
  tablet2: `${imgURL}?w=${bpMax.tablet2}&fit=thumb`,
  desktop: imgURL,
});

export const srcSetGenerator = imgURL => {
  const resImages = generateResponsiveImages(imgURL);
  return `${Object.keys(bpMax).map(bp => `${resImages[bp]} ${bpMax[bp]}w`)}`;
};
