import detectBrowserLanguage from 'detect-browser-language';
import { pathnames, locales } from '../config';

export const defaultLocale = () => {
  if (typeof window !== 'undefined' && typeof navigator !== 'undefined') {
    const browserLanguage = detectBrowserLanguage();
    return browserLanguage.includes('de') && browserLanguage.indexOf('de') === 0
      ? 'de'
      : 'en';
  }
  // fallback to english, if we do not have a browser / language setting
  return 'en';
};

export const getLocaleFromPathname = pathname => {
  let locale = pathname.split('/')[1];
  return Object.keys(locales).some(el => el === locale)
    ? locale
    : defaultLocale();
};

export const getEnglishLinkTranslation = link => {
  return locales.en.linksHeader[locales.de.linksHeader.indexOf(link)];
};

export const parseLinkName = link =>
  replaceUmlauts(link.toLowerCase().replace(' ', '-'));

export const getLinkTo = (localeCode, linkName) =>
  `/${localeCode}${linkName === 'Home' ? '/' : `/${parseLinkName(linkName)}/`}`;

export const switchLocaleRoute = (localeLink, pathname, query = '') => {
  if (localeLink === getLocaleFromPathname(pathname)) {
    return pathname;
  } else {
    let route = pathname.split('/')[2];
    let slug = pathname.split('/')[3];
    //pathname is the dictionary object, keys are english and values are german translations
    let nextTranslatedRoute =
      localeLink === 'de'
        ? `/${pathnames[route]}`
        : `/${getKeyByValue(pathnames, route)}`;

    //for the there is not translation so remove undefined
    nextTranslatedRoute = nextTranslatedRoute.includes('undefined')
      ? ''
      : nextTranslatedRoute;

    //append the slug, which doesn't require translations, of nested route in the pathname
    return `/${localeLink}${nextTranslatedRoute}${
      slug ? `/${slug}/` : '/'
    }${query}`;
  }
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const replaceUmlauts = str => str.replace(/\u00fc/g, 'ue');

export const stringToSlug = str => str.replace(/\s+/g, '-').toLowerCase();

export const shouldDisplayHeader = pathname =>
  pathname.split('/').filter(el => el !== '').length < 3;
