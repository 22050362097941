import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import AnimatedBackgroundBox from '../AnimatedBackgroundBox/AnimatedBackgroundBox';
import './FooterNav.scss';
import {
  getEnglishLinkTranslation,
  getLinkTo,
  getLocaleFromPathname,
} from '../../i18n/helpers';
import { locales } from '../../i18n/config';

const FooterNav = props => {
  const navPagesQueryResult = useStaticQuery(graphql`
    query {
      galleryPage: allContentfulGalleryPage {
        nodes {
          titleImageHighRes {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }

      servicesPage: allContentfulServicesPage {
        nodes {
          titleImageHighRes {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }

      talentsPage: allContentfulTalentsPage {
        nodes {
          titleImageHighRes {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }

      aboutPage: allContentfulAboutPage {
        nodes {
          titleImageHighRes {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `);

  const navPages = {
    galleryPage: navPagesQueryResult.galleryPage.nodes[0],
    servicesPage: navPagesQueryResult.servicesPage.nodes[0],
    talentsPage: navPagesQueryResult.talentsPage.nodes[0],
    aboutPage: navPagesQueryResult.aboutPage.nodes[0],
  };

  const { location } = props;
  const pathname = location.pathname;

  const images = {
    gallery:
      navPages &&
      navPages.galleryPage &&
      navPages.galleryPage.titleImageHighRes,
    services:
      navPages &&
      navPages.servicesPage &&
      navPages.servicesPage.titleImageHighRes,
    talents:
      navPages &&
      navPages.talentsPage &&
      navPages.talentsPage.titleImageHighRes,
    about:
      navPages && navPages.aboutPage && navPages.aboutPage.titleImageHighRes,
  };
  const lang = getLocaleFromPathname(pathname);

  return (
    <div className="footerNav">
      {locales[lang].linksHeader.map(
        link =>
          link !== 'Home' && (
            <Link
              key={link}
              className="footerNav__tile"
              to={getLinkTo(getLocaleFromPathname(pathname), link)}
            >
              <AnimatedBackgroundBox
                bg={
                  images[
                    (lang === 'de'
                      ? getEnglishLinkTranslation(link)
                      : link
                    ).toLowerCase()
                  ]
                }
                zoomBG
                hasNoHover
              >
                <h3 className="typography__header3 typography--uppercase footerNav__link">
                  {link}
                </h3>
              </AnimatedBackgroundBox>
            </Link>
          )
      )}
    </div>
  );
};

export default FooterNav;
