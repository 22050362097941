import React from 'react';
import { Link } from 'gatsby';
import Slider from 'react-slick';
import './HorizontalSlider.scss';
import { ResponsiveBgImage } from '../index';

import isTouchDevice from 'is-touch-device';

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
};

const HorizontalSlider = ({ data, fixedHeadline, largeHeader }) => (
  <div className="sliderContainer">
    <Slider
      {...settings}
      className={`horizontalSlider ${
        isTouchDevice ? 'horizontalSlider--touch' : ''
      }`}
    >
      {data.map(slide => (
        <Slide
          step={slide}
          fixedHeadline={fixedHeadline}
          largeHeader={largeHeader}
          key={slide.title || slide.image.title}
        />
      ))}
    </Slider>
    {fixedHeadline && (
      <h2 className={'typography__header1 sliderContainer__fixedHeadline'}>
        {fixedHeadline}
      </h2>
    )}
  </div>
);

const Slide = ({ step, fixedHeadline, largeHeader }) => (
  <div className="slideContainer">
    <ResponsiveBgImage image={step.image}>
      <div
        className={`slideContainer__content ${
          fixedHeadline ? 'slideContainer__content--spacedTop' : ''
        }`}
      >
        {step.title && (
          <h3
            className={`typography__header3 typography--uppercase slideContainer__headline`}
          >
            {step.title}
          </h3>
        )}
        {step.link && step.link.target && (
          <Link
            to={`${step.link.target}#2`}
            className={`typography__paragraph typography__link typography__link--readMore`}
            state={{
              ...step.link.state,
            }}
          >
            {step.link.label}
          </Link>
        )}
        {step.link && step.link.onClick && (
          <p
            onClick={step.link.onClick}
            className={`typography__paragraph typography__link typography__link--readMore`}
          >
            {step.link.label}
          </p>
        )}
      </div>
    </ResponsiveBgImage>
  </div>
);

export { HorizontalSlider, Slide };
