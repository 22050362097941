import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Location, navigate } from '@reach/router';
import './Header.scss';
import { locales } from '../../i18n/config';
import { getLocaleFromPathname, getLinkTo } from '../../i18n/helpers';
import classnames from 'classnames';

class Header extends Component {
  state = {
    isOpen: false,
  };
  openMenu = () => {
    this.setState({
      isOpen: true,
    });
  };
  closeMenu = () => {
    this.setState({
      isOpen: false,
    });
  };
  toggleMenu = () => {
    if (this.state.isOpen) {
      this.closeMenu();
    } else this.openMenu();
  };
  goToHomePage = () => {
    navigate('/');
  };

  render() {
    return (
      <Location>
        {({ location }) => (
          // TODO scrollable -> touchable?
          <header className={classnames(['headerBlock', 'scrollable'])}>
            <DesktopMenu
              pathname={location.pathname}
              goToHomePage={() => {
                this.goToHomePage();
              }}
            />

            <div className={'mobileMenu'}>
              <img
                alt={'Dropout Films logo'}
                className={'mobileMenu__logo'}
                src={'assets/icons/logo.svg'}
                onClick={() => this.goToHomePage()}
              />

              <MobileTrigger
                open={this.state.isOpen}
                toggleMenu={() => this.toggleMenu()}
              />

              <MobileMenu
                open={this.state.isOpen}
                pathname={location.pathname}
                closeMenu={() => this.closeMenu()}
              />
            </div>
          </header>
        )}
      </Location>
    );
  }
}

const DesktopMenu = ({ pathname, goToHomePage }) => (
  <div className={'desktopMenu'}>
    {locales[getLocaleFromPathname(pathname)].linksHeader.map(link => (
      <div key={`header-${link}`}>
        <Link
          className={`desktopMenu__link ${
            link === 'Home' ? 'desktopMenu__iconLink' : ''
          } `}
          to={getLinkTo(getLocaleFromPathname(pathname), link)}
        >
          {link === 'Home' ? (
            <img
              alt={'Dropout Films logo'}
              className={'desktopMenu__logo'}
              src={'assets/icons/logo.svg'}
              onClick={() => goToHomePage()}
            />
          ) : (
            <span>{link}</span>
          )}
        </Link>
      </div>
    ))}
  </div>
);

const MobileMenu = ({ open, pathname, closeMenu }) => (
  <nav
    className={`mobileMenu__navigation ${
      open ? 'mobileMenu__navigation--open' : ''
    }`}
  >
    {locales[getLocaleFromPathname(pathname)].linksHeader.map(link => (
      <Link
        key={`header-${link}`}
        className="mobileMenu__link"
        to={getLinkTo(getLocaleFromPathname(pathname), link)}
        onClick={() => closeMenu()}
      >
        <span className={'typography typography__header4'}>{link}</span>
      </Link>
    ))}
  </nav>
);

const MobileTrigger = ({ open, toggleMenu }) => (
  // TODO rename 'scrollable' into something more reasonable
  <div className={'mobileMenu__menuTrigger'} onClick={toggleMenu}>
    <div
      className={`mobileMenu__hamburger ${
        open ? 'mobileMenu__hamburger--open' : ''
      }`}
    >
      <span
        className={`mobileMenu__burgerBar ${
          open ? 'mobileMenu__burgerBar--open' : ''
        }`}
      />
    </div>
  </div>
);

export default Header;
