import React from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import classnames from 'classnames';

const makeOptions = (light, noWrap, limitedWidth) => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p
        className={classnames('typography__paragraph', {
          'typography--light': light,
          'typography--noWrap': noWrap,
          'typography__paragraph--limited': limitedWidth,
        })}
      >
        {children}
      </p>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h5 className={'typography__header5'}>{children}</h5>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className={'typography__list typography__list--unordered'}>
        {children}
      </ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ul className={'typography__list typography__list--ordered'}>
        {children}
      </ul>
    ),
    [INLINES.ASSET_HYPERLINK]: (node, children) => (
      <a
        href={node.data.target.fields.file.url}
        className={'typography__link'}
        rel={'noopener'}
        target={'_blank'}
      >
        {children}
      </a>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a
        href={node.data.uri}
        className={`typography__link ${light ? 'typography--light' : ''}`}
        rel={'noopener'}
        target={'_blank'}
      >
        {children}
      </a>
    ),
  },
});

export const RichTextRenderer = ({ content, light, noWrap, limitedWidth }) =>
  documentToReactComponents(content, makeOptions(light, noWrap, limitedWidth));
