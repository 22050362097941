import React, { Fragment } from 'react';
import './TransparentTextStage.scss';
import ReactResizeDetector from 'react-resize-detector';
import Frame, { FrameContextConsumer } from 'react-frame-component';

/*
DANGER! ACHTUNG! TODO! ;)

Due to Safari Desktop fix, which has to include this style, it's DUPLICATED here so that it can be injected
into the IFrame. The other option includes monkey-patching webpack config which doesn't look convincing to me (maintainability).
Yet another case for Styled Components.
 */

const transparentTextCSS = `
  @font-face {
    font-family: header;
    src: url('/assets/safari_fonts/OpenSans-ExtraBoldItalic.ttf'); /* Another danger zone */
    font-weight: normal;
    font-style: normal;
  }

  html, body, .frame-root, .frame-content {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: 'header', sans-serif;
    text-transform: uppercase;
    overflow: hidden;
    touch-action: auto;
    font-size: 42px;
  }
  
  @media (min-width: 768px) {
    html, svg {
      // font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
      font-size: calc(60px + (120 - 60) * ((100vw - 768px) / (1280 - 768)));
    }
  }
  @media (min-width: 1280px) {
    html, svg {
      font-size: 120px;
    }
  }

  svg {
    width: 100%;
    height: inherit;
    position: relative;
  }
  
  svg text {
    text-anchor: middle;
  }
  #alpha {
    fill: #fff;
  }
  #base {
    fill: #000;
    mask: url(#mask);
  }
`;

// SVG doesn't support text wrapping, and foreignObject doesn't support masks hence the workaround -- hence manual break
const Line = ({ text, i, linesCount }) => (
  <tspan x="50%" y="50%" dy={`${i + 1 - linesCount / 2}em`}>
    {text}
  </tspan>
);

const MultiLineMaskedText = ({ bpWidth, text }) => {
  const _bpWidth =
    bpWidth || (typeof window !== 'undefined' && window.innerWidth);
  const breakLines = text => {
    // we measured width and height of the letters 'W' and 'I' in our font style
    // and got this coefficient in between font size and average width
    const avWidthHeightCoefficient = 0.68;
    // basically recreating the media query for dynamic fot
    const fontSize =
      _bpWidth < 768
        ? 60
        : _bpWidth >= 768 && _bpWidth < 1280
        ? 60 + (120 - 60) * ((_bpWidth - 768) / (1280 - 768))
        : 120;
    // breakpoint width / (average width according to font size) + kerning
    const maxCharacters = _bpWidth
      ? Math.round(
          _bpWidth /
            (fontSize * avWidthHeightCoefficient +
              avWidthHeightCoefficient * 0.2)
        )
      : 10;
    const lines = [''];
    const words = text.split(' ');

    words.forEach(word => {
      const lastLine = lines[lines.length - 1];

      if (
        lastLine.length <= maxCharacters &&
        lastLine.length + word.length <= maxCharacters
      ) {
        lines[lines.length - 1] = `${lastLine} ${word}`;
      } else {
        lines.push(word);
      }
    });

    return lines;
  };

  const lines = breakLines(text);

  return (
    <Fragment>
      {/* CSS in JavaScript in CSS in SVG in React, voted the ugliest line of code in 2019 */}
      <text>
        {lines.map((line, i) => {
          return (
            <Line text={line.trim()} key={i} i={i} linesCount={lines.length} />
          );
        })}
      </text>
    </Fragment>
  );
};

const SafariMacOSWrapper = ({ children }) => {
  return (
    <Frame style={{ width: '100%', height: '100%', pointerEvents: 'none' }}>
      <FrameContextConsumer>
        {// Callback is invoked with iframe's window and document instances
        ({ document, window }) => {
          return (
            <Fragment>
              <script>document.addEventListener('touchstart', {});</script>
              <style
                dangerouslySetInnerHTML={{
                  __html: transparentTextCSS,
                }}
              />
              {children}
            </Fragment>
          );
        }}
      </FrameContextConsumer>
    </Frame>
  );
};

const TransparentTextStage = ({ text, children, iconPath }) => {
  // because safari is a bit ekhm, this only works on safari desktop, but not mobile (which is exactly what we want)
  const isSafari =
    typeof window !== 'undefined' &&
    window.navigator.vendor === 'Apple Computer, Inc.';

  const Wrapper = isSafari ? SafariMacOSWrapper : Fragment;

  return (
    <ReactResizeDetector handleWidth refreshMode={'debounce'}>
      {({ width }) => (
        <section className={'transparentTextStage'}>
          <Wrapper>
            <svg>
              <defs>
                <mask id="mask" x="0" y="0" width="100%" height="100%">
                  <rect id="alpha" x="0" y="0" width="100%" height="100%" />
                  <MultiLineMaskedText text={text} bpWidth={width} />
                </mask>
              </defs>
              <rect id="base" x="0" y="0" width="100%" height="100%" />
            </svg>
          </Wrapper>
          <div className={'transparentTextStage__linkContainer'}>
            {children}
          </div>
          {iconPath && (
            <img
              src={iconPath}
              alt="transparent text stage icon"
              className="transparentTextStage__icon"
            />
          )}
        </section>
      )}
    </ReactResizeDetector>
  );
};

export default TransparentTextStage;
