import React from 'react';
import Player from '@vimeo/player';
import { isMobile, getUA } from 'react-device-detect';
import { MoonLoader } from 'react-spinners';
import './VideoPlayer.scss';

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);

    const aspectRatio = this.props.aspectRatio.split(':');

    this.state = {
      invertedAspectRatio: aspectRatio[1] / aspectRatio[0],
      aspectRatio: aspectRatio[0] / aspectRatio[1],
      showSpinner: true,
      isVideoPlaying: false,
      showVideoControls: false,
      showVideoError: false,
      mouseMoving: false,
      isVideoEnded: false,
    };

    this.mouseTimeout = undefined;
    this.videoPlayTimeout = undefined;
  }

  componentDidMount = () => {
    this.updateContainerDimensions().then(() => {
      if (getUA !== 'ReactSnap') {
        this.createPlayer();
      }
    });

    typeof window !== 'undefined' &&
      window.addEventListener(
        'resize',
        this.updateContainerDimensions.bind(this)
      );

    this.props.videoControls && document.body.classList.add('overflow-hidden');
  };

  componentWillUnmount = () => {
    typeof window !== 'undefined' &&
      window.removeEventListener(
        'resize',
        this.updateContainerDimensions.bind(this)
      );

    this.props.videoControls &&
      document.body.classList.remove('overflow-hidden');
    clearTimeout(this.mouseTimeout);
    clearTimeout(this.videoPlayTimeout);
  };

  updateContainerDimensions() {
    const { aspectRatio } = this.state;
    if (this.wrapper) {
      const containerWidth = this.wrapper.clientWidth;
      const containerHeight = this.wrapper.clientHeight;
      const containerAspectRatio = containerWidth / containerHeight;

      let videoHeight = containerHeight;
      let videoWidth = containerWidth;
      let offsetY = 0;
      let offsetX = 0;

      if (containerAspectRatio > aspectRatio) {
        videoHeight = containerWidth / aspectRatio;
        offsetY = (videoHeight - containerHeight) / -2;
      } else {
        videoWidth = containerHeight * aspectRatio;
        offsetX = (videoWidth - containerWidth) / -2;
      }
      return new Promise(resolve =>
        this.setState(
          {
            videoHeight,
            videoWidth,
            offsetY,
            offsetX,
          },
          resolve
        )
      );
    }
  }

  getInitialOptions() {
    const { videoId, quality, videoControls } = this.props;

    const { videoWidth, videoHeight } = this.state;

    let mobileConfig = () => {
      // prevent ssr error
      if (getUA === 'ReactSnap') {
        return {
          background: true,
        };
      } else if (isMobile && videoControls) {
        return {
          background: false,
          playsinline: false,
        };
      } else if (!isMobile && videoControls) {
        return {
          background: true,
        };
      } else if (!videoControls || !isMobile) {
        return { background: true };
      }
    };

    return {
      id: videoId,
      width: videoWidth,
      height: videoHeight,
      loop: !videoControls,
      dnt: true,
      responsive: true,
      quality: quality,
      autopause: this.props.autopause ? this.props.autopause : false,
      muted: this.props.muted ? this.props.muted : false,
      ...mobileConfig(),
    };
  }

  createPlayer() {
    const {
      videoControls,
      onPlayVideo,
      onPlayerError,
      onLoadVideo,
    } = this.props;

    this.player = new Player(this.videoContainer, this.getInitialOptions());

    if (onPlayVideo) {
      this.player.on('play', video => onPlayVideo());
    }

    if (onLoadVideo) {
      this.player.on('loaded', () => onLoadVideo());
    }

    if (videoControls) {
      this.createFullscreenPlayer();
    }

    if (onPlayerError) {
      this.player.on('error', () => onPlayerError());
    }
  }

  createFullscreenPlayer() {
    const { videoId } = this.props;

    this.player
      .loadVideo(videoId)
      .then(() => {
        this.player.setVolume(0.5);
        this.player.play();
      })
      .catch(err => {
        console.log(err);
        this.setState({ showVideoError: true });
      });

    this.player.on('play', e => {
      this.setState({ showSpinner: false, isVideoPlaying: true });

      // prevent to see last vimeo frame with cuepoint event
      this.player
        .getDuration()
        .then(duration => this.player.addCuePoint(duration - 0.2));
      clearTimeout(this.videoPlayTimeout);
    });

    this.player.on('cuepoint', e => {
      this.player.pause() && this.setState({ isVideoEnded: true });
    });
  }

  showControls(e) {
    //hide controls if user don't move the mouse for 1500ms
    if (this.state.mouseMoving) {
      clearTimeout(this.mouseTimeout);
      this.mouseTimeout = setTimeout(
        () => this.setState({ mouseMoving: false }),
        1500
      );
    } else {
      this.setState({ mouseMoving: true });
    }
  }

  toggleVideoPlay() {
    this.state.isVideoPlaying
      ? this.player.pause() && this.setState({ isVideoPlaying: false })
      : this.player.play() && this.setState({ isVideoPlaying: true });
  }

  toggleVideoSound() {
    this.state.isVideoMute
      ? this.player.setVolume(0.5) && this.setState({ isVideoMute: false })
      : this.player.setVolume(0) && this.setState({ isVideoMute: true });
  }

  renderFullscreenControls() {
    const {
      showSpinner,
      isVideoPlaying,
      isVideoMute,
      mouseMoving,
      showVideoError,
      isVideoEnded,
    } = this.state;

    const TogglePlayControl = () => (
      <div
        onMouseEnter={e => this.showControls(e)}
        onClick={() => mouseMoving && this.toggleVideoPlay()}
        className={'videoControls__toggleIcon'}
      >
        <img
          alt={`${isVideoPlaying ? 'pause' : 'play'}-icon`}
          src={`assets/icons/${isVideoPlaying ? 'pause' : 'play'}.svg`}
        />
      </div>
    );

    const ToggleVolumeControl = () => (
      <div
        onMouseEnter={e => this.showControls(e)}
        onClick={() => mouseMoving && this.toggleVideoSound()}
        className={'videoControls__toggleIcon'}
      >
        <img
          alt={`sound-${isVideoMute ? 'off' : 'on'}-icon`}
          src={`assets/icons/sound_${isVideoMute ? 'off' : 'on'}.svg`}
        />
      </div>
    );

    const ToggleControls = () =>
      !showSpinner &&
      !showVideoError && (
        <div
          className={`videoControls__iconsBlock ${
            mouseMoving ? 'videoControls__iconsBlock--fadeIn' : ''
          }`}
        >
          <TogglePlayControl />
          <ToggleVolumeControl />
        </div>
      );

    const PlayAgainControl = () => (
      <div
        className={'videoControls__reloadIconBlock'}
        onClick={() =>
          //restart video
          this.player
            .setCurrentTime(0)
            .then(
              () => this.player.play() && this.setState({ isVideoEnded: false })
            )
        }
      >
        <img src="assets/icons/reload.svg" alt="reload-icon" />
        {/* todo: define play again message in de and en */}
        <p className={'typography__link'}>Play again?</p>
      </div>
    );

    const ErrorVideoControl = () => (
      <div className={'videoControls__reloadIconBlock'}>
        {/* todo: define error message in de and en */}
        <p className={'typography__paragraph'}>
          There were some error loading the video
        </p>
      </div>
    );

    return (
      <div className={'videoControls'} onMouseMove={e => this.showControls(e)}>
        {isVideoEnded ? <PlayAgainControl /> : <ToggleControls />}

        {showVideoError && <ErrorVideoControl />}

        {showSpinner && !showVideoError && (
          <div className={'videoControls__loadingSpinner'}>
            <MoonLoader size={60} color={'white'} loading={showSpinner} />
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      videoHeight,
      videoWidth,
      offsetX,
      offsetY,
      isVideoEnded,
      invertedAspectRatio,
    } = this.state;
    const { children, videoControls, onClose } = this.props;

    const frameStyle = videoControls
      ? {
          zIndex: 10100,
          top: `calc(50vh - (100vw * ${invertedAspectRatio} / 2))`,
          opacity: isVideoEnded ? 0.5 : 1,
        }
      : {
          width: videoWidth + 'px',
          height: videoHeight + 'px',
          top: offsetY + 'px',
          left: offsetX + 'px',
        };

    return (
      <div
        ref={node => (this.wrapper = node)}
        className={`videoWrapper ${
          videoControls ? 'videoWrapper--videoControls' : ''
        }`}
      >
        <div
          className={'videoFrame'}
          ref={node => (this.videoContainer = node)}
          style={frameStyle}
        />

        {videoControls && !isMobile && this.renderFullscreenControls()}

        {children}

        {videoControls && (
          <div className={'videoControls__closeIcon'} onClick={() => onClose()}>
            <img alt={'close-icon'} src="/assets/icons/close.svg" />
          </div>
        )}
      </div>
    );
  }
}

VideoPlayer.defaultProps = {
  aspectRatio: '16:9',
  quality: '540p',
};

export default VideoPlayer;
