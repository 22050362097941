import React, { Component } from 'react';
import classnames from 'classnames';
import './Carousel.scss';

const preventDefault = event => {
  let e = event || (typeof window !== 'undefined' && window.event);
  if (e.preventDefault) e.preventDefault();
  e.returnValue = false;
};

function onStartScroll() {
  typeof window !== 'undefined' &&
    window.addEventListener('DOMMouseScroll', preventDefault, false);
  document.addEventListener('wheel', preventDefault, { passive: false });
  setTimeout(() => {
    onStopScroll();
  }, 700);
}

function onStopScroll() {
  typeof window !== 'undefined' &&
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
  document.removeEventListener('wheel', preventDefault, { passive: false });
}

class Carousel extends Component {
  constructor(props, state) {
    super(props, state);
    this.state = {
      previousActiveScreenIndex: 0,
      activeScreenIndex: 0,
      deltaY: 0,
      deltaX: 0,
      scrollingNavigation: { next: true, previous: false }, // indicated whether going to previous/next screen is allowed
    };

    this.lastScroll = new Date();
    this.scrollingNavigationAtTouchStart = {}; // the same, but memoization for when the touch actually started
  }

  componentWillUpdate = (nextProps, nextState) => {
    if (
      nextState.activeScreenIndex !== this.state.activeScreenIndex &&
      this.props.location &&
      this.props.location.hash.length <= 2
    ) {
      typeof window !== 'undefined' &&
        window.history.replaceState(
          null,
          null,
          this.props.location.pathname +
            this.props.location.search +
            '#' +
            nextState.activeScreenIndex
        );
    }
  };
  get validChildren() {
    if (this.props.children && Array.isArray(this.props.children)) {
      return this.props.children.filter(child => {
        // ignore empty children
        if (!child) return false;
        const valid =
          child && child.type && child.type.displayName === 'CarouselScreen';

        if (!valid) {
          console.warn(
            'Carousel accepts only CarouselScreen components as children, but received something else.',
            (child.type && child.type.displayName) || child.type || child
          );
        }

        return valid;
      });
    } else if (
      this.props.children &&
      this.props.children.type &&
      this.props.children.type.displayName === 'CarouselScreen'
    ) {
      return this.props.children;
    } else {
      return [];
    }
  }

  handleScroll = event => {
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    // firefox is weird and it captures different scroll values from mouse than webkit based browsers. so be it
    const snapThreshold = isFirefox ? 7 : 30; // pixels you have to scroll to get to the next slide

    const snapDelay = isFirefox ? 1000 : 700; // can't scroll again until n miliseconds passed
    if (
      this.lastScroll.getTime() - new Date().getTime() < -1 * snapDelay && // prevent multiple scrolling in short time
      Math.abs(event.deltaY) > snapThreshold // make sure the gesture was intentional
    ) {
      if (event.deltaY > 0) {
        this.nextScreen();
      } else {
        this.previousScreen();
      }
      this.lastScroll = new Date();
    }
  };

  setTouchDelta(x, y, callback) {
    this.setState({ deltaY: Math.floor(y), deltaX: Math.floor(x) }, () =>
      callback ? callback() : undefined
    );
    document
      .getElementsByTagName('html')[0]
      .style.setProperty('--touch-deltaY', `${Math.floor(y)}px`);
  }

  handleTouch = event => {
    const deltaY = event.changedTouches[0].clientY - this.touchClientY;
    const deltaX = event.changedTouches[0].clientX - this.touchClientx;

    if (
      (deltaY < 0 &&
        this.state.scrollingNavigation.next &&
        !this.scrollingNavigationAtTouchStart.previous) ||
      (deltaY > 0 &&
        this.state.scrollingNavigation.previous &&
        !this.scrollingNavigationAtTouchStart.next)
    ) {
      this.setTouchDelta(deltaX, deltaY);
    }
  };

  handleTouchStart = event => {
    // touch started while scrolling
    if (
      !this.state.scrollingNavigation.next ||
      !this.state.scrollingNavigation.previous
    ) {
      this.scrollingNavigationAtTouchStart = this.state.scrollingNavigation;
    }

    this.touchClientY = event.touches[0].clientY;
    this.touchClientx = event.touches[0].clientX;
  };

  handleTouchEnd = () => {
    this.scrollingNavigationAtTouchStart = {};

    const snapThreshold = 30;

    if (
      Math.abs(this.state.deltaY) > snapThreshold &&
      Math.abs(this.state.deltaY) > Math.abs(this.state.deltaX)
    ) {
      if (this.state.deltaY > 0) {
        this.resetTouchDelta(() => this.previousScreen());
      } else {
        this.resetTouchDelta(() => this.nextScreen());
      }
    }
  };

  nextScreen() {
    if (!this.state.scrollingNavigation.next) {
      return;
    }

    onStartScroll();
    const screensCount = this.validChildren.length;
    const newScreenIndex =
      this.state.activeScreenIndex + 1 < screensCount
        ? this.state.activeScreenIndex + 1
        : this.state.activeScreenIndex;

    this.setState({
      activeScreenIndex: newScreenIndex,
      previousActiveScreenIndex: this.state.activeScreenIndex,
    });
  }

  previousScreen() {
    if (!this.state.scrollingNavigation.previous) {
      return;
    }
    onStartScroll();
    const newScreenIndex =
      this.state.activeScreenIndex > 0
        ? this.state.activeScreenIndex - 1
        : this.state.activeScreenIndex;

    this.setState({
      activeScreenIndex: newScreenIndex,
      previousActiveScreenIndex: this.state.activeScreenIndex,
    });
  }

  componentDidMount() {
    if (!this.scrollingHandler) {
      this.scrollingHandler = document.addEventListener(
        'wheel',
        this.handleScroll
      );
    }

    if (!this.touchStartHandler) {
      this.touchStartHandler = document.addEventListener(
        'touchstart',
        this.handleTouchStart
      );
    }

    if (!this.touchMoveHandler) {
      this.touchMoveHandler = document.addEventListener(
        'touchmove',
        this.handleTouch
      );
    }

    if (!this.touchEndHandler) {
      this.touchEndHandler = document.addEventListener(
        'touchend',
        this.handleTouchEnd
      );
    }
  }

  componentWillUnmount() {
    document.removeEventListener('wheel', this.handleScroll);
    document.removeEventListener('touchstart', this.handleTouchStart);
    document.removeEventListener('touchmove', this.handleTouch);
    document.removeEventListener('touchforcechange', this.handleTouch);
    document.removeEventListener('touchend', this.handleTouchEnd);
  }

  goToScreen = async (
    n,
    withSlidesTransitions = false,
    positionAnchor = {}
  ) => {
    const newIndex = num =>
      num > this.state.activeScreenIndex
        ? this.state.activeScreenIndex + 1
        : this.state.activeScreenIndex - 1;

    if (withSlidesTransitions) {
      if (n[0] === '#') {
        const split = n.split('-');
        const slideNumber = split[split.length - 1].replace('#', '');
        const id =
          split.length > 1 &&
          split
            .slice(0, split.length - 1)
            .join('-')
            .replace('#', '');

        if (this.props.children.length - 1 < +slideNumber) {
          return;
        }
        return this.setState(
          {
            activeScreenIndex: +slideNumber,
            previousActiveScreenIndex: this.state.activeScreenIndex,
          },
          () => {
            const element = id && document.getElementById(id);
            element && element.scrollIntoView(positionAnchor);
            typeof window !== 'undefined' &&
              window.history.replaceState(
                null,
                null,
                this.props.location.pathname +
                  this.props.location.search +
                  '#' +
                  slideNumber
              );
          }
        );
      } else {
        return this.setState({
          activeScreenIndex: n,
          previousActiveScreenIndex: this.state.activeScreenIndex,
        });
      }
    }

    if (n !== this.state.activeScreenIndex) {
      this.setState(
        {
          activeScreenIndex: newIndex(n),
          previousActiveScreenIndex: this.state.activeScreenIndex,
        },
        () => {
          if (n !== newIndex(n)) {
            setTimeout(
              () => this.goToScreen(n),
              this.props.transitionTime * 1000
            );
          }
        }
      );
    }
  };

  // the below {next: boolean, previous: boolean} in order to differentiate between next/previous
  setScrollingNavigation = obj => {
    this.setState({ scrollingNavigation: obj });
  };

  resetTouchDelta = callback => {
    this.setTouchDelta(0, 0, callback);
  };

  render() {
    const enhancedChildren = React.Children.map(
      this.validChildren,
      (child, index) => {
        return React.cloneElement(child, {
          index,
          activeScreenIndex: this.state.activeScreenIndex,
          active: this.state.activeScreenIndex === index,
          previousActiveScreenIndex: this.state.previousActiveScreenIndex,
          deltaY: this.state.deltaY,
          scrollingNavigation: this.state.scrollingNavigation,
          setScrollingNavigation: this.setScrollingNavigation,
          resetTouchDelta: this.resetTouchDelta,
          goToScreen: this.goToScreen,
        });
      }
    );

    return (
      <div className="carousel">
        <ul className={'carousel__navigation'}>
          {enhancedChildren.map((child, i) => (
            <li
              key={`slide-${i}`}
              className={classnames({
                carousel__navigationElement: true,
                'carousel__navigationElement--active':
                  i === this.state.activeScreenIndex,
              })}
              onClick={() => this.goToScreen(i, true)}
            />
          ))}
        </ul>

        <div className="carousel__container">{enhancedChildren}</div>
      </div>
    );
  }
}

export default Carousel;
