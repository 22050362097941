import React, { Component } from 'react';
import classnames from 'classnames';
import Img from 'gatsby-image';
import './AnimatedBackgroundBox.scss';
import ResponsiveBgImage from '../ResponsiveBgImage/ResponsiveBgImage';

class AnimatedBackgroundBox extends Component {
  constructor(props) {
    super(props);
    this.interval = undefined;
    this.idx = 0;
    this.state = {
      activeBgIndex: 0,
    };
  }

  handleMouseOver = () => {
    if (this.interval === undefined) {
      this.interval = setInterval(this.renderBgImage, 1000);
    }
  };

  renderBgImage = () => {
    this.idx === this.props.bg.length - 1 ? (this.idx = 0) : (this.idx += 1);

    this.setState({ activeBgIndex: this.idx });
  };

  handleMouseLeave = () => {
    clearInterval(this.interval);
    this.interval = undefined;
    this.idx = 0;
    this.setState({ activeBgIndex: 0 });
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { children, bg, zoomBG, hasNoHover } = this.props;

    if (Array.isArray(bg)) {
      return (
        <div
          className={`AnimatedBackgroundBox AnimatedBackgroundBox__MultiBgBox ${classnames(
            {
              'AnimatedBackgroundBox--hasNoHover': hasNoHover,
            }
          )}
          `}
          onMouseEnter={this.handleMouseOver}
          onMouseLeave={this.handleMouseLeave}
        >
          <div className={'AnimatedBackgroundBox__Content'}>{children}</div>
          <Img
            className={'AnimatedBackgroundBox__bgImage'}
            fluid={bg[this.state.activeBgIndex].fluid}
            style={{
              // Clear the default position
              position: '',
            }}
          />
        </div>
      );
    } else {
      // have a single image, that can be zoomed
      // <AnimatedBackgroundBox bg={'path-to-image'} zoomBG hasNoHover>
      return (
        <div
          className={`AnimatedBackgroundBox AnimatedBackgroundBox__SingleBgBox ${classnames(
            {
              'AnimatedBackgroundBox__SingleBgBox--zoomBG': zoomBG,
              'AnimatedBackgroundBox--hasNoHover': hasNoHover,
            }
          )} `}
        >
          <div className={'AnimatedBackgroundBox__Content'}>{children}</div>
          <div
            className={`AnimatedBackgroundBox__bgImage ${
              zoomBG ? 'AnimatedBackgroundBox__bgImage--zoomBG' : ''
            }`}
          >
            <ResponsiveBgImage image={bg} />
          </div>
        </div>
      );
    }
  }
}

export default AnimatedBackgroundBox;
