import React from 'react';
import './ProjectMosaic.scss';
import ResponsiveBgImage from '../ResponsiveBgImage/ResponsiveBgImage';

const ProjectMosaic = ({ title, images, ...props }) => {
  return (
    <div {...props}>
      {title && title.length > 1 && (
        <h2
          className={
            'typography__header2 ProjectMosaic__title typography--uppercase'
          }
        >
          {title}
        </h2>
      )}

      <div className={'ProjectMosaic__wrapper'}>
        {images.map(image => (
          <div className={'ProjectMosaic__tile'} key={image.fluid.srcWebp}>
            <ResponsiveBgImage image={image} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectMosaic;
