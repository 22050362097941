import React from 'react';
import './Transitions.scss';
import classnames from 'classnames';

function ScrollTransition({
  active,
  previousActiveScreenIndex,
  index,
  children,
  activeScreenIndex,
  transitionOptions,
  deltaY,
}) {
  const options = transitionOptions ? transitionOptions : {};

  const inTransition = deltaY !== 0;

  const shouldBeOutToTop =
    !active &&
    previousActiveScreenIndex === index &&
    !options.lastOfKind &&
    deltaY === 0;

  const shouldBeInFromTop =
    index < previousActiveScreenIndex &&
    active &&
    !options.lastOfKind &&
    deltaY === 0;

  const shouldBeInFromBottom =
    index > previousActiveScreenIndex && active && deltaY === 0;
  const shouldBeOutToBottom =
    previousActiveScreenIndex === index &&
    activeScreenIndex === index - 1 &&
    deltaY === 0;
  const shouldBeStatic =
    ((!active && activeScreenIndex > index) ||
      (index < previousActiveScreenIndex && active)) &&
    options.lastOfKind;

  // dragging
  const shouldBeOutToTopPartial = active && deltaY < 0 && !options.lastOfKind;
  const shouldBeInFromBottomPartial =
    index === activeScreenIndex + 1 && deltaY < 0;
  const shouldBeInFromTopPartial =
    index === activeScreenIndex - 1 && deltaY > 0 && !options.lastOfKind;
  const shouldBeOutToBottomPartial =
    active && deltaY > 0 && previousActiveScreenIndex > index;

  // finished animation states
  const shouldBeAbove =
    index < activeScreenIndex && !inTransition && !options.lastOfKind;
  const shouldBeUnder = index > activeScreenIndex && !inTransition;

  let classes = classnames(
    'transition__scroll',
    {
      'transition__scroll--above': shouldBeAbove,
    },
    {
      'transition__scroll--under': shouldBeUnder && !inTransition,
    },
    { 'transition__scroll--active': active },
    {
      'transition__scroll--outToTop': shouldBeOutToTop,
    },
    {
      'transition__scroll--outToBottom': shouldBeOutToBottom,
    },
    {
      'transition__scroll--static': shouldBeStatic,
    },
    {
      'transition__scroll--outToTopPartial': shouldBeOutToTopPartial,
    },
    {
      'transition__scroll--inFromBottomPartial': shouldBeInFromBottomPartial,
    },
    {
      'transition__scroll--inFromTopPartial': shouldBeInFromTopPartial,
    },
    {
      'transition__scroll--outToBottomPartial': shouldBeOutToBottomPartial,
    },
    {
      'transition__scroll--inFromTop': shouldBeInFromTop,
    },
    {
      'transition__scroll--inFromBottom': shouldBeInFromBottom,
    }
  );

  return <div className={classes}>{children}</div>;
}

export default ScrollTransition;
