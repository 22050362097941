import React, { Fragment } from 'react';
import './LimitedWidthText.scss';

const LimitedWidthText = ({ text, children }) => (
  <Fragment>
    {text ? (
      <p className="typography typography__header6">{text}</p>
    ) : (
      <div className="limitedText">{children}</div>
    )}
  </Fragment>
);
export default LimitedWidthText;
